<template>
  <section id="products">
    <b-card>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :current-page="productsTable.currentPage"
        :items="productsTable.items"
        :fields="productsTable.fields"
        :sort-by.sync="productsTable.sortBy"
        :sort-desc.sync="productsTable.sortDesc"
        :sort-direction="productsTable.sortDirection"
        :filter="productsTable.filter"
        :filter-included-fields="productsTable.filterOn"
        :tbody-tr-class="rowClass"
      >
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="productsTable.currentPage"
            :total-rows="productsTable.totalRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BPagination,
  BCardBody,
  BCard,
} from "bootstrap-vue"
import axios from "axios"

export default {
  components: {
    BTable,
    BPagination,
    BCardBody,
    BCard,
  },
  data() {
    return {
      productsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: "id",
        sortDesc: true,
        sortDirection: "desc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: '',
          content: '',
        },
        fields: [
          { key: "id", label: "id", sortable: true },
          { key: "sku", label: "sku", sortable: true },
          { key: "name", label: "name", sortable: true },
          { key: "producer_name", label: "producer", sortable: true, },
          { key: "on_hand", label: "on hand", sortable: true },
          { key: "on_hand_actually", label: "on hand actually", sortable: true },
          { key: "on_demand", label: "on demand", sortable: true },
          { key: "price", label: "price", sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      queryParams: {},
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.on_hand !== item.on_hand_actually) return 'table-warning'
    },
    getProducts() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/products/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: this.queryParams,
        })
        .then(response => {
          this.productsTable.items = response.data.results
          this.productsTable.totalRows = response.data.count * 2
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    handlePageChange(value) {
      this.queryParams["limit"] = 10
      this.queryParams["offset"] = (value-1) * 10
      this.getProducts()
    },
  },
  created() {
    this.getProducts()
  }
}
</script>
